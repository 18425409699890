<template>
  <div class="project-audit-log">
    <ul class="audit-list mt-4">
      <li v-for="auditLog in limitedAuditLogs" :key="auditLog.id">
        <div class="audit-entry mb-4">
          <div class="audit-header">
            <small
              ><strong>
                {{ $datetime(auditLog.createdAt) }}
                {{ $fromNow(auditLog.createdAt) }}
              </strong>
            </small>
            {{ $t('from') }} {{ auditLog.user.firstname }} {{ auditLog.user.lastname }}
          </div>
          <div class="audit-body">
            <!-- <pre>{{ auditLog }}</pre> -->
            <p>
              <strong>{{ translateFieldPath(auditLog.fieldPath) }}</strong> wurde geändert zu
              <strong>"{{ formatAuditLogValue(auditLog.newValue) }}"</strong>. Alter Wert:
              <strong>"{{ formatAuditLogValue(auditLog.oldValue) }}"</strong>.
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {},
  props: {
    user: null,
    auditLogs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      limit: 10,
      isLimit: false,
    };
  },
  computed: {
    ...mapGetters(['getEnumValues']),

    /**
     * apply auditLog limit, return the last [limit] auditLogs from the auditLogs array if limit is set
     */
    limitedAuditLogs() {
      return this.isLimit ? this.auditLogs.slice(-1 * this.limit) : this.auditLogs;
    },
  },
  methods: {
    ...mapActions(['fetchEnumValues']),

    /**
     * Format Audit logs:
     * - reference objects: return the name property
     * - i18n placeholders: return translated string
     * - string values: return as they are
     * @param value
     */
    formatAuditLogValue(value) {
      if (value && typeof value === 'object' && value.name) {
        return value.name;
      } else if (value && typeof value === 'object' && value.company) {
        return value.company;
      } else if (value && typeof value === 'object' && value.manufacturer && value.type) {
        return value.manufacturer + ' ' + value.type;
      }

      // console.log(this.$dayjs(value, 'YYYY-MM-DD', true).isValid(), value);

      // check if value is a UTC date
      const utcDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
      const isDate = utcDatePattern.test(value);
      if (isDate) {
        return this.$datetime(value);
      }

      return this.translateIfPlaceholder(value);
    },

    /**
     * Check if a value is a placeholder
     * @param {string} value - The value to check
     * @returns {boolean} - True if the value is a placeholder, false otherwise
     */
    isPlaceholder(value) {
      const placeholderPattern = /^(?=.*[A-Z_])[A-Z0-9_]{4,}$/;
      return typeof value === 'string' && placeholderPattern.test(value);
    },

    // isDate

    /**
     * Translate a value if it's a placeholder, otherwise return it as is
     * @param {string} value - The value to potentially translate
     * @returns {string} - The translated or original value
     */
    translateIfPlaceholder(value) {
      return this.isPlaceholder(value) ? this.$t(value) : value;
    },

    /**
     * Translate a fieldPath that may contain dot notation.
     * Each part of the dot-separated string is translated separately.
     * @param {string} fieldPath - The field path to translate
     * @returns {string} - The fully translated field path
     */
    translateFieldPath(fieldPath) {
      if (typeof fieldPath !== 'string') return fieldPath;

      return fieldPath
        .split('.') // Split at dots
        .map((part) => this.$t(part)) // Translate each part if it's a placeholder
        .join(' / '); // Recombine the translated parts with dots
    },
  },

  async mounted() {
    // this.auditLogs = [];
  },
};
</script>

<style lang="scss" scoped>
.audit-entry {
  border-top: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;
}

.audit-header {
  background: $bg;
  padding: 0.2em 0.5em;
  color: white;
}

.audit-body {
  padding: 0.6em 0.2em;
}

.audit-body p {
  padding: 0;
  margin: 0;
}

ul.audit-list {
  padding: 0;
  list-style: none;
}
</style>

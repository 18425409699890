<template>
  <div class="project-audit-log">
    <h4 class="mt-2 mb-2">
      {{ $t('audit_log') + ' (' + auditLogs.length + ')' }}
      <small>(max. 100 Einträge innerhalb der letzten 14 Tage)</small>
    </h4>
    <AuditLogs :auditLogs="auditLogs" :user="getCurrentUser" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AuditLogs from '@/components/AuditLogs';

export default {
  components: { AuditLogs },
  props: {
    project: { type: Object },
  },
  data() {
    return {
      auditTableStateConfig: {
        name: 'project-audits',
        pagination: {
          page: 0,
          pageSize: 100,
        },
        filters: {
          action: { value: null },
          user: { value: null },
          dateRange: {
            range: [
              this.$dayjs()
                .subtract(14, 'days')
                .toDate(),
            ],
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isClient', 'getCurrentUser']),
    ...mapGetters(['getAudits', 'getAuditCount', 'getTableState']),

    tableStateStore() {
      return this.getTableState(this.auditTableStateConfig.name);
    },
    auditLogs() {
      return this.getAudits;
    },
  },
  methods: {
    ...mapActions(['fetchAudits', 'initTableState']),

    /**
     * Load remote table data
     */
    async loadAuditData() {
      // hard wire current project to filter
      this.tableStateStore.customFilters = {
        resourceType: { value: 'PROJECT' },
        resourceId: { value: this.project.id },
      };

      await this.fetchAudits({
        pagination: {
          skip: this.tableStateStore.skip(),
          pageSize: this.tableStateStore.pagination.pageSize,
        },
        sorting: this.tableStateStore.sorting,
        filters: { ...this.tableStateStore.filters, ...this.tableStateStore.customFilters },
      });
    },
  },

  async created() {
    await this.initTableState(this.auditTableStateConfig);
  },

  async mounted() {
    await this.loadAuditData();
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="project-installation">
    <b-row>
      <b-col lg="5" md="5" sm="12">
        <legend>
          <strong>{{ $t('roughPlanning') }}</strong>
        </legend>

        <b-form-group
          horizontal
          :label="$t('operationRegistrationAt')"
          label-for="operationRegistrationAt"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
          :invalid-feedback="invalidFeedbackOperationRegistration"
          :state="operationRegisrtationRequirementsState('operationRegistrationAt')"
        >
          <Calendar v-model="operationRegistrationAt" :placeholder="$t('date')" :showWeek="true">
          </Calendar>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('electricMeterChangedAt')"
          label-for="electricMeterChangedAt"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <Calendar
            v-model="electricMeterChangedAt"
            selectionMode="single"
            class="p-column-filter"
            dateFormat="dd.mm.yy"
            :placeholder="$t('date')"
            :showWeek="true"
            :yearNavigator="true"
            :monthNavigator="true"
            yearRange="2019:2025"
          >
          </Calendar>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('electrician')"
          label-for="electrician"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
          :invalid-feedback="invalidFeedbackNA"
          :state="requirementsValidState('electrician')"
        >
          <multiselect
            :placeholder="$t('electrician')"
            v-model="project.electrician"
            id="electrician"
            :options="electricians"
            :allow-empty="true"
            :show-labels="false"
            track-by="number"
            label="name"
          >
          </multiselect>
        </b-form-group>
        <b-form-group
          horizontal
          :label="$t('originalInstallationType')"
          label-for="originalInstallationType"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('originalInstallationType')"
            v-model="project.installation.originalInstallationType"
            :options="getOriginalInstallationTypes"
            :allow-empty="false"
            :selectLabel="$t('press_enter_to_select')"
            :selectedLabel="$t('selected')"
            deselectLabel
          >
            <template slot="singleLabel" slot-scope="{ option }">{{ $t(option) }}</template>
            <template slot="option" slot-scope="{ option }">{{ $t(option) }}</template>
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('description')"
          label-for="description"
          label-class="text-md-right"
          :label-cols="2"
          breakpoint="md"
        >
          <b-form-textarea
            id="description"
            v-model="project.installation.description"
            placeholder="..."
            rows="8"
            max-rows="8"
          ></b-form-textarea>
        </b-form-group>
      </b-col>

      <b-col lg="7" md="7" sm="12">
        <legend>
          <strong>{{ $t('installationAppointments') }}</strong>
        </legend>

        <DataTable
          class="p-datatable-sm "
          :value="getAppointments"
          :loading="isLoading"
          :rowHover="true"
          editMode="cell"
          sortMode="multiple"
          :multiSortMeta="multiSortMeta"
          @cell-edit-complete="onCellEditComplete"
          @sort="onSort($event)"
          @filter="onFilter($event)"
          :rowClass="rowClass"
        >
          <template #header>
            <div class="table-header">
              <div class="table-header-left"></div>
              <div class="table-header-right">
                <div class="button-right">
                  <button class="btn btn-success mb-2 right" @click="onCreateAppointment">
                    <i class="fa fa-plus-circle mr-2"></i>
                    {{ $t('new') }}
                  </button>
                </div>
              </div>
            </div>
          </template>
          <template #empty>
            {{ $t('no_data_found') }}
          </template>
          <template #loading>
            {{ $t('loading') }}
          </template>

          <template #groupheader="slotProps">
            <strong>{{ slotProps.data.weekday }}</strong>
          </template>

          <Column
            field="appointmentType"
            :header="$t('appointmentType')"
            :styles="{ width: '7%' }"
            headerClass="center"
            className="center"
          >
            <template #body="slotProps">
              <b-badge :variant="getAppointmentTypeColor(slotProps.data.appointmentType)">
                {{ $t(slotProps.data.appointmentType) }}
              </b-badge>
            </template>
            <template #editor="{ data, field }">
              <Dropdown
                v-model="data[field]"
                :options="getEnumValues('AppointmentType')"
                :placeholder="$t('select')"
              >
                <template #option="slotProps">
                  <b-badge :variant="getAppointmentTypeColor(slotProps.option)">
                    {{ $t(slotProps.option) }}
                  </b-badge>
                </template>
                <template #value="slotProps">
                  <b-badge :variant="getAppointmentTypeColor(slotProps.value)">
                    {{ $t(slotProps.value) }}
                  </b-badge>
                </template>
              </Dropdown>
            </template>
          </Column>

          <Column
            field="appointmentState"
            :header="$t('appointmentState')"
            :styles="{ width: '7%' }"
            headerClass="center"
            className="center"
          >
            <template #body="slotProps">
              <b-badge :variant="getAppointmentStateColor(slotProps.data.appointmentState)">
                {{ $t(slotProps.data.appointmentState) }}
              </b-badge>
            </template>
            <template #editor="{ data, field }">
              <Dropdown
                v-model="data[field]"
                :options="getEnumValues('AppointmentState')"
                :placeholder="$t('select')"
              >
                <template #option="slotProps">
                  <b-badge :variant="getAppointmentStateColor(slotProps.option)">
                    {{ $t(slotProps.option) }}
                  </b-badge>
                </template>
                <template #value="slotProps">
                  <b-badge :variant="getAppointmentStateColor(slotProps.value)">
                    {{ $t(slotProps.value) }}
                  </b-badge>
                </template>
              </Dropdown>
            </template>
          </Column>

          <Column
            field="appointmentApprovedState"
            :header="$t('appointmentApprovedState_short')"
            :styles="{ width: '7%' }"
            headerClass="center"
            className="center"
          >
            <template #body="slotProps">
              <b-badge :variant="getYesNoStateColor(slotProps.data.appointmentApprovedState)">
                {{ $t(slotProps.data.appointmentApprovedState) }}
              </b-badge>
            </template>
            <template #editor="{ data, field }">
              <Dropdown v-model="data[field]" :options="yesNo" :placeholder="$t('select')">
                <template #option="slotProps">
                  <b-badge :variant="getYesNoStateColor(slotProps.option)">
                    {{ $t(slotProps.option) }}
                  </b-badge>
                </template>
                <template #value="slotProps">
                  <b-badge :variant="getYesNoStateColor(slotProps.value)">
                    {{ $t(slotProps.value) }}
                  </b-badge>
                </template>
              </Dropdown>
            </template>
          </Column>

          <Column field="installationStartAt" :header="$t('start')">
            <template #body="slotProps">
              <div>{{ $weekDay(slotProps.data[slotProps.column.field]) }}</div>
              <div>{{ $datetime(slotProps.data[slotProps.column.field]) }}</div>
            </template>
            <template #editor="slotProps">
              <Calendar
                v-model="slotProps.data[slotProps.column.field]"
                :showTime="true"
                :manualInput="true"
                :showWeek="true"
                :stepMinute="15"
              >
              </Calendar>
            </template>
          </Column>
          <Column
            field="installationEndAt"
            :header="$t('end')"
            :styles="{ width: '7%' }"
            headerClass="center"
            className="center"
          >
            <template #body="slotProps">
              {{ $time(slotProps.data[slotProps.column.field]) }}
            </template>
            <template #editor="slotProps">
              <Calendar
                v-model="slotProps.data[slotProps.column.field]"
                :showTime="true"
                :manualInput="true"
                :stepMinute="15"
              >
              </Calendar>
            </template>
          </Column>

          <Column field="employees" :header="$t('employee')">
            <template #body="slotProps">
              <multiselect
                class="multiselect-employees"
                v-model="slotProps.data.employees"
                :options="filteredEmployees"
                track-by="number"
                v-on:search-change="onFilterEmployees"
                v-on:select="onSelectEmployee(slotProps)"
                :filter="true"
                :internal-search="false"
                :close-on-select="false"
                :clear-on-select="false"
                :multiple="true"
                :show-labels="false"
                :allow-empty="true"
                :deselectLabel="$t('deselectLabel')"
                selectLabel=""
                selectedLabel=""
                :placeholder="'Mitarbeiter ' + $t('select') + '/suchen'"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.firstname }} {{ option.lastname }}
                </template>
                <template slot="option" slot-scope="{ option }">
                  {{ option.firstname }} {{ option.lastname }}
                </template>
                <template slot="tag" slot-scope="{ option }">
                  <div>{{ option.firstname }} {{ option.lastname }}</div>
                </template>
                <template slot="noResult">
                  <div>{{ $t('no_results') }}</div>
                </template>
              </multiselect>
            </template>
          </Column>

          <Column field="description" :header="$t('notes')" :styles="{ width: '15%' }">
            <template #editor="slotProps">
              <InputText v-model="slotProps.data[slotProps.column.field]" />
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <ConfirmButton
                colorVariant="white"
                :disabled="emailButtonDisabled(slotProps.data)"
                :callbackId="slotProps.data"
                :confirmCallback="onSendAppointmentConfirmationMail"
              >
                <template v-slot:buttonText>
                  <i class="link-icon fa fa-lg fa-envelope mr-2"></i>
                </template>
                <template v-slot:header> {{ $t('confirm_appointment') }}? </template>
                <template v-slot:confirmButtonText>
                  {{ $t('confirm_appointment') }}
                </template>
                <template v-slot:content>
                  E-Mail-Bestätigung wird versendet an:
                  <strong v-if="slotProps.data.project && slotProps.data.project.customer.email">
                    {{ slotProps.data.project.customer.email }}</strong
                  >
                </template>
              </ConfirmButton>
              <ConfirmButton
                colorVariant="white"
                :callbackId="slotProps.data.number"
                :confirmCallback="onDeleteAppointment"
              >
                <template v-slot:buttonText>
                  <i class="link-icon fa fa-lg fa-times mr-2"></i>
                </template>
                <template v-slot:header> {{ $t('delete_appointment') }}? </template>
                <template v-slot:confirmButtonText>
                  {{ $t('delete_appointment') }}
                </template>
              </ConfirmButton>
            </template>
          </Column>
        </DataTable>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
// import DatePicker from 'vue2-datepicker';
import { yesNo } from '@/helpers/enums';
import Calendar from 'primevue/calendar';
import {
  getAppointmentTypeColor,
  getAppointmentStateColor,
  getYesNoStateColor,
} from '../../helpers/colors';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';
import ConfirmButton from '@/components/ConfirmButton';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';

export default {
  components: {
    Multiselect,
    // DatePicker,
    Calendar,
    DataTable,
    Column,
    ConfirmButton,
    Dropdown,
    InputText,
  },
  props: {
    project: { type: Object },
  },
  data() {
    return {
      filteredProjects: [],
      filteredEmployees: [],
      loading: false,
      multiSortMeta: [{ field: 'installationStartAt', order: -1 }],
      tableState: {
        filters: {
          from: { value: null, matchMode: FilterMatchMode.EQUALS },
          to: { value: null, matchMode: FilterMatchMode.EQUALS },
          project: { value: this.project.id, matchMode: FilterMatchMode.EQUALS },
        },
        customFilters: { calendarWeek: [] },
      },
    };
  },
  computed: {
    ...mapGetters([
      'getEnumValues',
      'getOriginalInstallationTypes',
      'getInstallationTeams',
      'getInstallationTypes',
      'isLoading',
      'getAppointments',
      'getEmployees',
    ]),

    /**
     *
     */
    electricians() {
      return this.project.client && this.project.client.electricians
        ? this.project.client.electricians
        : [];
    },

    /**
     *
     */
    electricMeterChangedAt: {
      get() {
        return this.$calendarFormat(this.project.installation.electricMeterChangedAt);
      },
      set(value) {
        this.project.installation.electricMeterChangedAt = value;
      },
    },
    operationRegistrationAt: {
      get() {
        return this.$calendarFormat(this.project.installation.operationRegistrationAt);
      },
      set(value) {
        this.project.installation.operationRegistrationAt = value;
      },
    },
    /**
     * @todo DRY
     */
    invalidFeedbackNA() {
      return 'Voraussetzung NA';
    },
    /**
     * @todo DRY
     */
    invalidFeedbackOperationRegistration() {
      return 'Voraussetzung IN';
    },

    yesNo,
  },
  methods: {
    ...mapActions([
      'fetchEnumValues',
      'uploadProjectAttachment',
      'deleteProjectAttachment',
      'fetchAppointments',
      'createAppointment',
      'updateAppointment',
      'initAppointment',
      'fetchEmployees',
      'deleteAppointment',
      'sendAppointmentMail',
    ]),
    getYesNoStateColor,
    getAppointmentTypeColor,
    getAppointmentStateColor,

    /**
     *
     */
    async onSendAppointmentConfirmationMail(appointment) {
      // console.log('onSendAppointmentConfirmationMail():', appointment);
      await this.sendAppointmentMail({
        appointmentNumber: appointment.number,
      });
    },

    /**
     *
     */
    emailButtonDisabled(appointment) {
      return !(appointment.project && appointment.project.customer.email) ? true : false;
    },

    /**
     *
     */
    async onFilterEmployees(query) {
      console.log(query);
      this.filteredEmployees = [];
      this.getEmployees.map((employee) => {
        if (
          (employee.firstname && employee.firstname.toLowerCase().indexOf(query) >= 0) ||
          (employee.lastname && employee.lastname.toLowerCase().indexOf(query) >= 0)
        ) {
          console.log(employee);
          this.filteredEmployees.push(employee);
        }
      });
    },

    /**
     *
     */
    async onSelectEmployee(row) {
      const { data } = row;
      console.log('onSelectEmployee():', data);
      if (data.number === '_new') {
        await this.createAppointment(data);
      } else {
        await this.updateAppointment(data);
      }
    },

    /**
     *
     */
    onInstallationAtChanged(date, installationAppointment) {
      // console.log(date);
      // console.log(installationAppointment);
      installationAppointment.calendarWeek = this.$week(date).toString();
      installationAppointment.installationAt = this.$dayjs(date)
        .hour(8)
        .minute(0);
    },

    /**
     * send appointment data to server after editing a cell
     * @param {*} event
     */
    async onCellEditComplete(event) {
      let { data, newValue, field } = event;
      // console.log(event);
      // console.log(data, newValue, field);

      if (field === 'installationStartAt') {
        // update date grouping (day of week)
        data.weekday = this.$dayjs(newValue)
          .startOf('day')
          .format('dddd DD.MM.YYYY');

        // set installationEndAt to installationStartAt + 4 hour
        data.installationEndAt = this.$dayjs(newValue)
          .add(8, 'hour')
          .toDate();
      }

      data[field] = newValue;
      data.project = this.project;

      if (data.number === '_new') {
        await this.createAppointment(data);
      } else {
        await this.updateAppointment(data);
      }
    },

    /**
     * When selecting a single day, add 6 days to the date filter to represent a whole week.
     * Then close the calendar overlay.
     * @param {*} value
     */
    async onCalendarWeekFilter(value) {
      this.tableState.customFilters.calendarWeek[1] = this.$dayjs(value)
        .add(6, 'days')
        .toDate();
      this.tableState.filters.from.value = this.tableState.customFilters.calendarWeek[0];
      this.tableState.filters.to.value = this.tableState.customFilters.calendarWeek[1];
      this.$refs.appointmentCalendar.overlayVisible = false;
      await this.loadAppointmentData();
    },

    /**
     *
     */
    onCreateAppointment() {
      // console.log('onCreateAppointment():');
      this.initAppointment();
    },

    onDeleteAppointment(appointmentNumber) {
      // console.log('onDeleteAppointment():', appointmentNumber);
      this.deleteAppointment(appointmentNumber);
    },

    /**
     *
     */
    rowClass(data) {
      return data.number === '_new' ? 'new' : null;
    },

    /**
     * Load remote table data
     */
    async loadAppointmentData() {
      if (this.project.id !== '_new') {
        await this.fetchAppointments({ filters: this.tableState.filters });
      }
    },
    /**
     * check requirementsValidState to show mark input fields
     * @todo DRY
     */
    requirementsValidState(field) {
      if (!this.project.netRegistrationRequirements) return false;

      const requirement = this.project.netRegistrationRequirements.find(
        (requirement) => requirement.name === field
      );
      return requirement && requirement.fullfilledState === 'YES' ? true : false;
    },
    /**
     * check operationRegisrtationRequirementsState to show mark input fields
     * @todo DRY
     */
    operationRegisrtationRequirementsState(field) {
      if (!this.project.operationRegistrationRequirements) return false;

      const requirement = this.project.operationRegistrationRequirements.find(
        (requirement) => requirement.name === field
      );
      return requirement && requirement.fullfilledState === 'YES' ? true : false;
    },
  },

  async mounted() {
    await this.fetchEnumValues('AppointmentType');
    await this.fetchEnumValues('AppointmentState');
    await this.fetchEmployees({
      page: 0,
      pageSize: 1000,
      sortField: 'lastname',
      sortOrder: 1,
      filters: { activeState: { value: 'ACTIVE' } },
    });
    this.filteredEmployees = this.getEmployees;
    await this.loadAppointmentData();
  },
};
</script>

<style scoped lang="scss">
::v-deep .center .p-column-header-content {
  justify-content: center;
}

::v-deep .p-datatable .p-datatable-tbody td {
  text-align: left;
}
::v-deep .p-datatable .p-datatable-tbody td.center {
  text-align: center;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new {
  background: $light-green;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new:hover {
  background: $green;
}

::v-deep tr.p-rowgroup-header {
  background: $gray-100;
  span {
    font-weight: 700;
  }
  .p-row-toggler {
    vertical-align: middle;
    margin-right: 0.25rem;
  }
}

.p-multiselect {
  width: 100%;
  min-width: 300px;
}

.multiselect-project {
  max-width: 500px;
  min-width: 350px;
  display: inline-block;
}
.multiselect-employees {
  min-width: 230px;
  display: inline-block;
}

::v-deep .multiselect__tags {
  border: 0;
  border-radius: 0;
}

::v-deep .multiselect-employees .multiselect__input {
  padding-top: 1em;
}

/** Fix Dropdown overlay */
.p-datatable-responsive-scroll > .p-datatable-wrapper {
  min-height: 37rem;
}
</style>
